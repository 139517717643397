<template>
    <div class="h-24 w-24 m-2">
        <img src="../../../storage/app/public/images/Logo.jpg" alt="Logo">
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>