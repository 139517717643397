<template>
    <div class="min-h-screen flex flex-col container">
        <div class="top-0 left-5 right-5 z-50 bg-white fixed">
            <headerMobile v-if="isMobile"></headerMobile>
            <headerDesktop
                v-else
                v-bind="$attrs"
                class="h-24 font-body w-full"
            ></headerDesktop>
        </div>
        <main class="flex-grow mt-24">
            <Notification
                :message="notification.message"
                :visible="notification.visible"
            />
            <slot></slot>
        </main>
        <div class="bg-main_color text-white">
            <Footer
                class="footer bg-main_color p-10 text-white container justify-around"
            ></Footer>
        </div>
    </div>
</template>

<script setup>
import headerDesktop from "../Components/Header/HeaderDesktop.vue";
import headerMobile from "../Components/Header/HeaderMobile.vue";
import Footer from "../Components/Footer/Footer.vue";
import MiniCartDrawer from "../Components/MiniCartDrawer.vue";
import { ref, onMounted, onBeforeUnmount, computed, useAttrs } from "vue";
import { useNotificationStore } from "../Stores/notificationStore";
import Notification from "../Components/Notification.vue";

const notification = useNotificationStore();
const attrs = useAttrs();
const windowWidth = ref(window.innerWidth);

const isMobile = computed(() => windowWidth.value < 1280);

const handleResize = () => {
    windowWidth.value = window.innerWidth;
};

onMounted(() => {
    window.addEventListener("resize", handleResize);
});

onBeforeUnmount(() => {
    window.removeEventListener("resize", handleResize);
});
</script>

<style lang="scss" scoped></style>
