<template>
    <div>
        <nav class="flex flex-col">
            <h6 class="footer-title text-2xl">Каталог</h6>
            <div
                v-for="category in categories"
                :key="category.id"
                @click="navigateToCategory(category.slug)"
                class="text-xl"
            >
                <a class="link link-hover">{{ category.name }}</a>
            </div>
        </nav>
        <nav>
            <h6 class="footer-title text-2xl">Компанія</h6>
            <Link href="/about" class="link link-hover text-xl">Про нас</Link>
            <Link href="/partners" class="link link-hover text-xl"
                >Партнерам</Link
            >
            <div class="flex items-center mt-10">
                <link
                    href="https://awards.infcdn.net/2024/circle_v3.css"
                    rel="stylesheet"
                />
                <div
                    id="circle_bw"
                    data-length="31"
                    onclick="if(event.target.nodeName.toLowerCase() != 'a') {window.open(this.querySelector('.circle_bw_link').href);return 0;}"
                >
                    <p class="circle_bw_year">2024</p>
                    <div class="circle_bw_name">
                        <a
                            class="circle_bw_link"
                            target="_blank"
                            href="https://restaurantguru.com/Kolkheti-Odessa-5"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="178px"
                                height="178px"
                                viewBox="0 0 178 178"
                            >
                                <defs>
                                    <path
                                        id="circle_bw_name-arc"
                                        d="M 12 89 a 77 77 0 0 0 154 0"
                                    ></path>
                                </defs>
                                <text
                                    class="circle_bw_name_txt f14"
                                    fill="#000"
                                    text-anchor="middle"
                                >
                                    <textPath
                                        startOffset="50%"
                                        xlink:href="#circle_bw_name-arc"
                                    >
                                        Колхети
                                    </textPath>
                                </text>
                            </svg>
                        </a>
                    </div>
                    <div class="circle_bw_nom">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="200px"
                            height="200px"
                            viewBox="0 0 200 200"
                        >
                            <defs>
                                <path
                                    id="circle_bw_nom-arc1"
                                    d="M 30 100 a 70 70 0 1 1 140 0"
                                ></path>
                            </defs>
                            <text
                                class="circle_bw_nom_txt f14"
                                fill="#000"
                                text-anchor="middle"
                            >
                                <textPath
                                    startOffset="50%"
                                    xlink:href="#circle_bw_nom-arc1"
                                >
                                    Рекомендовано
                                </textPath>
                            </text>
                        </svg>
                    </div>
                    <a
                        class="circle_bw_home"
                        style="font-size: 0"
                        href="https://restaurantguru.com"
                        target="_blank"
                        >Restaurant Guru</a
                    >
                </div>
            </div>
        </nav>
        <nav>
            <h6 class="footer-title text-2xl">Контакти</h6>
            <div v-for="rest in restaurants" :key="rest.id" class="mb-2">
                <p class="text-xl">Ресторан на {{ rest.address }}</p>
                <span class="flex flex-row text-xl"
                    ><p class="mr-2">тел.:</p>
                    <a :href="`tel:${rest.phone}`">{{ rest.phone }}</a></span
                >
            </div>

            <div class="flex flex-col mx-5">
                <button
                    class="my-10 px-5 py-3 border bg-white rounded-xl text-black text-2xl"
                >
                    Поскаржитись
                </button>
                <div
                    class="flex flex-row items-center justify-between w-full py-5 mb-5"
                >
                    <a
                        href="https://www.instagram.com/kolheti_hinkalnaya?igsh=ZmU2NGFnbWJpdHhq"
                        class="w-16 h-16 flex items-center justify-center"
                    >
                        <img
                            src="/storage/product-images/inst-circled.svg"
                            alt=""
                        />
                    </a>
                    <a
                        href="https://www.facebook.com/KolhetiHinkalnaya"
                        class="w-16 h-16 flex items-center justify-center"
                    >
                        <img
                            src="/storage/product-images/facebook-circled.svg"
                            alt=""
                        />
                    </a>
                    <!-- <a
                        href="https://www.google.com/search?client=safari&sca_esv=7e63893fda4bafb0&hl=uk-ua&cs=0&output=search&q=%D0%A5%D1%96%D0%BD%D0%BA%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0+%22%D0%9A%D0%BE%D0%BB%D1%85%D0%B5%D1%82%D1%96%22&ludocid=17327410768135432481&lsig=AB86z5VwgnZCrjhn6oot2Dov6bkh&kgs=b75f48a961fb5c49&shndl=30&shem=lsp&source=sh/x/loc/hdr/m1/3#lrd=0x40c63320a1c87b0d:0xf0775449fffda921,1,,,,"
                        class="w-16 h-16 flex items-center justify-center text-white"
                    >
                        <img src="/storage/product-images/google.svg" alt="" />
                    </a> -->
                </div>
                <div class="flex flex-row items-center justify-around w-full">
                    <img
                        class="w-20 h-16"
                        src="/storage/product-images/visa.svg"
                        alt=""
                    />
                    <img
                        class="w-20 h-16"
                        src="/storage/product-images/mastercard.svg"
                        alt=""
                    />
                </div>
            </div>
        </nav>
    </div>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";
import { Link } from "@inertiajs/vue3";
import { Inertia } from "@inertiajs/inertia";
import { computed } from "vue";

const page = usePage();
const categories = computed(() => page.props?.categories || []);

const filteredCategories = computed(() =>
    categories.value.filter((category) => category.id !== 11)
);

const restaurants = page.props.restaurants;

const navigateToCategory = (categorySlug) => {
    Inertia.visit(`/category/${categorySlug}`);
};
</script>

<style lang="scss" scoped></style>
