import "./bootstrap";
import "../css/app.css";
import VueTheMask from "vue-the-mask";

import Mainlayout from "./Layouts/MainLayout.vue";
import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";

import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy";
import pinia from "./store";

const appName = import.meta.env.VITE_APP_NAME || "Колхеті Хінкальна";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        return resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ).then((module) => {
            const page = module.default;
            page.layout = page.layout || Mainlayout;
            return page;
        });
    },

    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(pinia)
            .use(VueTheMask)
            .use(ZiggyVue);

        // Монтируем приложение
        app.mount(el);

        // Удаляем атрибут data-page
        el.removeAttribute("data-page");

        return app;
    },

    progress: {
        color: "#4B5563",
    },
});
