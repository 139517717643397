<template>
    <div
        class="flex flex-row container justify-around items-center border bg-main_color text-white rounded-xl h-14 z-40"
    >
        <div
            role="tablist"
            class="tabs"
            v-for="category in categories"
            :key="category.id"
            @click="navigateToCategory(category.slug)"
        >
            <a role="tab" class="tab text-lg text-white z-40">{{
                category.name
            }}</a>
        </div>
    </div>
</template>

<script setup>
import { Inertia } from "@inertiajs/inertia";
import { usePage } from "@inertiajs/vue3";
import { computed } from "vue";

const page = usePage();
const categories = computed(() => page.props?.categories || []);

const navigateToCategory = (categorySlug) => {
    Inertia.visit(`/category/${categorySlug}`);
};
</script>

<style lang="scss" scoped></style>
