import { defineStore } from "pinia";

export const useNotificationStore = defineStore("notification", {
    state: () => ({
        message: "",
        visible: false,
    }),
    actions: {
        showNotification(message) {
            this.message = message;
            this.visible = true;

            // Убираем уведомление через 5 секунд
            setTimeout(() => {
                this.visible = false;
            }, 5000);
        },
    },
});
