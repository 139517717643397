<template>
    <div>
        <div class="flex flex-row my-auto items-center">
            <div class="flex items-center px-3 py-2">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-10 h-10 bg-submain_color text-white rounded-full p-2"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                    />
                </svg>
                <div class="mx-5 flex flex-col items-center">
                    <label for="rest" class="text-sm font-light">
                        Оберіть ресторан для замовлення:
                    </label>
                    <select
                        id="rest"
                        v-model="selectedAddress"
                        @change="handleAddressChange"
                        class="py-0 border-none font-semibold focus:outline-none focus:ring-0 text-black"
                    >
                        <option
                            v-for="item in restaurants"
                            :key="item.id"
                            :value="item.address"
                        >
                            {{ item.address }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-if="selectedRestaurant">
                <div class="flex flex-row items-center ml-5">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-10 h-10 mr-3 text-white bg-submain_color rounded-full p-2"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                        />
                    </svg>
                    <span class="font-semibold">{{ selectedRestaurant.phone }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { usePage } from '@inertiajs/vue3';

const page = usePage();
const restaurants = page.props.restaurants;
const selectedRestaurant = reactive({});
const selectedAddress = ref('');

onMounted(() => {
    const storedRestaurant = localStorage.getItem('selectedRestaurant');
    if (storedRestaurant) {
        const restaurant = JSON.parse(storedRestaurant);
        Object.assign(selectedRestaurant, restaurant);
        selectedAddress.value = restaurant.address;
    }

    // Слушаем событие 'restaurant-selected'
    window.addEventListener('restaurant-selected', (event) => {
        const restaurant = event.detail;
        Object.assign(selectedRestaurant, restaurant);
        selectedAddress.value = restaurant.address;
    });
});

const handleAddressChange = () => {
    const foundRestaurant = restaurants.find(
        (item) => item.address === selectedAddress.value
    );
    if (foundRestaurant) {
        Object.assign(selectedRestaurant, foundRestaurant);
        localStorage.setItem(
            'selectedRestaurant',
            JSON.stringify(foundRestaurant)
        );
    }
};
</script>

<style lang="scss" scoped></style>