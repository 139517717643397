<template>
    <div class="flex justify-between items-center relative">
        <RestaurantModal class="mx-3 border border-xl border-gray-800" />
        <div class="relative z-40">
            <div class="drawer">
                <input id="my-drawer" type="checkbox" class="drawer-toggle" />
                <div class="drawer-content">
                    <label for="my-drawer" class="btn drawer-button">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            class="text-2xl inline-block w-5 h-5 stroke-current"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            ></path>
                        </svg>
                    </label>
                </div>
                <div class="drawer-side z-50">
                    <label
                        for="my-drawer"
                        aria-label="close sidebar"
                        class="drawer-overlay"
                    ></label>
                    <ul
                        class="menu bg-base-200 text-base-content min-h-full w-80 p-4"
                    >
                        <li class="bg-main_color rounded-t-lg mb-2">
                            <h2 class="font-bold text-white text-2xl">Меню:</h2>
                        </li>
                        <li
                            v-for="category in categories"
                            :key="category.id"
                            @click="navigateToCategory(category.slug)"
                        >
                            <a
                                class="link link-hover bg-gray-200 mb-2 uppercase font-md text-xl"
                                >{{ category.name }}</a
                            >
                        </li>
                        <li class="mt-5">
                            <Link
                                href="/contacts"
                                role="tab"
                                class="link link-hover text-lg"
                                >Ресторани</Link
                            >
                        </li>
                        <li>
                            <Link
                                href="/shipping-and-payment"
                                role="tab"
                                class="link link-hover text-lg"
                                >Доставка та оплата</Link
                            >
                        </li>
                        <li
                            class="mt-10 flex flex-row justify-center text-white items-center bg-main_color rounded-b-xl px-2 py-5"
                        >
                            <label for="rest" class="text-sm font-md"
                                >Оберіть ресторан для замовлення:</label
                            >
                            <select
                                id="rest"
                                @change="handleAddressChange"
                                class="select select-ghost w-full max-w-xs mx-5"
                            >
                                <option
                                    v-for="item in restaurants"
                                    :value="item.address"
                                    class="disabled selected"
                                >
                                    <div class="flex flex-col">
                                        <span class="text-xl text-white">
                                            {{ item.address }}
                                        </span>
                                    </div>
                                </option>
                            </select>
                            <div>
                                <div
                                    v-if="selectedAddress"
                                    class="flex flex-row items-center justify-center"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-10 h-10 mr-3 text-white bg-submain_color rounded-full p-2"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                                        />
                                    </svg>
                                    <span
                                        class="font-semibold"
                                        :value="selectedAddress.phone"
                                        >{{ selectedAddress.phone }}</span
                                    >
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="relative z-30">
            <Link href="/" role="tab" class="link link-hover text-lg">
                <ApplicationLogo></ApplicationLogo>
            </Link>
        </div>
        <div class="relative z-40">
            <MiniCartDrawer></MiniCartDrawer>
        </div>
    </div>

    <div
        v-if="!isCheckoutRoute"
        class="flex flex-col items-center border rounded-xl bg-amber-100"
    >
        <label for="rest" class="text-sm text-center font-md">
            Ресторан, з якого бажаєте отримати замовлення:
        </label>
        <select
            id="rest"
            v-model="selectedAddress.address"
            @change="handleAddressChange"
            class="select select-ghost w-full max-w-xs mx-5"
        >
            <option
                v-for="item in restaurants"
                :key="item.id"
                :value="item.address"
            >
                {{ item.address }}
            </option>
        </select>
    </div>
</template>

<script setup>
import RestaurantModal from "../RestaurantModal.vue";
import Select from "../Select.vue";
import MiniCartDrawer from "../MiniCartDrawer.vue";
import ApplicationLogo from "../ApplicationLogo.vue";
import { usePage } from "@inertiajs/vue3";
import { Inertia } from "@inertiajs/inertia";
import { reactive, onMounted, computed, watch, ref } from "vue";
import { Link } from "@inertiajs/vue3";

const page = usePage();
const categories = page.props.categories;
const restaurants = page.props.restaurants;

const selectedAddress = reactive({
    address: "",
    phone: "",
});

// Следим за изменениями URL и проверяем, является ли текущий маршрут checkout
const isCheckoutRoute = ref(page.url === "/checkout");

// Отслеживаем изменения URL с помощью watch
watch(
    () => page.url,
    (newUrl) => {
        isCheckoutRoute.value = newUrl === "/checkout";
    }
);

onMounted(() => {
    // Получаем сохранённый ресторан из localStorage, если он существует
    const storedRestaurant = localStorage.getItem("selectedRestaurant");
    if (storedRestaurant) {
        const restaurant = JSON.parse(storedRestaurant);
        selectedAddress.address = restaurant.address;
        selectedAddress.phone = restaurant.phone;
    } else {
        // Устанавливаем значение по умолчанию, если в localStorage ничего нет
        const defaultRestaurant = restaurants[0];
        if (defaultRestaurant) {
            selectedAddress.address = defaultRestaurant.address;
            selectedAddress.phone = defaultRestaurant.phone;
        }
    }
});

const handleAddressChange = (event) => {
    const selectedAdr = event.target.value;
    const foundAddress = restaurants.find(
        (item) => item.address === selectedAdr
    );
    if (foundAddress) {
        selectedAddress.address = foundAddress.address;
        selectedAddress.phone = foundAddress.phone;

        // Сохраняем выбранный ресторан в localStorage
        localStorage.setItem(
            "selectedRestaurant",
            JSON.stringify(foundAddress)
        );
    }
};

const navigateToCategory = (categorySlug) => {
    Inertia.visit(`/category/${categorySlug}`);
};
</script>

<style lang="scss" scoped></style>
