<template>
    <div v-if="visible" class="alert alert-success" role="alert">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 shrink-0 stroke-current"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
        <span>{{ message }}</span>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: "Your purchase has been confirmed!",
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.alert {
    position: fixed;
    top: 20px;
    right: 0;
    margin-right: 20px;
    z-index: 1000;
    padding: 15px;
    background-color: #4caf50;
    color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    max-width: 25%;
    width: calc(100% - 40px);
    box-sizing: border-box;
    transform: translateX(100%); /* Смещаем за пределы экрана */
    transition: transform 1.5s ease; /* Плавный переход, время увеличено до 1.5 секунд */
    opacity: 0;
}

.alert-success {
    /* Когда компонент отображен */
    transform: translateX(0); /* Показываем уведомление, выдвигая его */
    opacity: 1;
    transition: transform 1.5s ease; /* Переход также для отображения */
}

@media (min-width: 768px) {
    .alert {
        width: 25%;
    }
}

.h-6 {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
</style>
