<template>
    <div class="bg-white">
        <div
            v-if="showSidebar"
            :style="{ width: sidebarWidth }"
            @mouseover="expandSidebar"
            @mouseleave="collapseSidebar"
            class="bg-white h-screen z-50 fixed border-l shadow-2xl shadow-gray-400 top-0 right-0 z-1000 transition-width duration-1000 font-body flex flex-col justify-between sidebar"
        >
            <div class="flex-1 overflow-y-auto">
                <div
                    class="w-full bg-submain_color px-1 py-2 flex justify-between items-center"
                >
                    <svg
                        @click="toggleSidebar"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-10 h-10 text-white cursor-pointer"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                        />
                    </svg>
                    <p class="text-2xl text-white font-bold">
                        ({{ totalItems }})
                    </p>
                </div>
                <div
                    v-for="product in cartItems"
                    :key="product.id"
                    class="flex items-center justify-items-center"
                    :class="{
                        'flex-row mr-5 mt-5': sidebarWidth !== '120px',
                        'flex-col': sidebarWidth === '120px',
                    }"
                >
                    <div
                        class="flex flex-col ml-3 transition-width duration-1000"
                        v-if="sidebarWidth !== '120px'"
                    >
                        <div class="grid grid-cols-3">
                            <div class="w-28 h-28">
                                <img
                                    class="rounded-2xl w-24 h-24"
                                    :src="imagePath(product.image_path)"
                                />
                            </div>
                            <div class="col-span-2">
                                <span class="text-black text-md font-medium">{{
                                    product.name
                                }}</span>
                                <div
                                    class="text-black text-md font-medium flex flex-col"
                                >
                                    <span
                                        class="text-sm text-gray-500 font-base mt-2"
                                        >{{ product.weight }}</span
                                    >
                                    <div
                                        class="flex flex-row justify-between items-center max-w-26 h-8"
                                    >
                                        <span
                                            class="text-sm text-gray-500 font-base mt-2"
                                            >₴{{ product.price }}</span
                                        >
                                        <div class="flex items-center px-2">
                                            <button
                                                @click="
                                                    decreaseQuantity(product.id)
                                                "
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="currentColor"
                                                    class="size-8 bg-submain_color rounded-full text-white"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                                    />
                                                </svg>
                                            </button>

                                            <span
                                                class="text-2xl font-bold mx-3"
                                                >{{ product.quantity }}</span
                                            >
                                            <button
                                                @click="
                                                    increaseQuantity(product.id)
                                                "
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="currentColor"
                                                    class="size-8 bg-submain_color rounded-full text-white"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <button
                                            @click="removeFromCart(product.id)"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="size-8 text-gray-400"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <img
                            class="p-2 rounded-2xl w-32 h-32 z-50 bg-white"
                            :src="imagePath(product.image_path)"
                        />
                    </div>
                </div>
            </div>
            <div
                v-if="sidebarWidth !== '120px'"
                class="bg-main_color p-4 text-white"
            >
                <p class="font-bold text-xl">Кількість: {{ totalItems }}</p>
                <p class="font-bold text-xl">
                    Проміжна вартість: {{ totalPrice.toFixed(2) }} грн
                </p>
                <p
                    class="font-bold text-xl"
                    v-if="storedDeliveryMethod === 'pickup'"
                >
                    Проміжна вартість зі знижкою:
                    {{ discountedTotalPrice.toFixed(2) }} грн
                </p>
                <p class="font-bold text-xl">
                    Упаковка: {{ totalPackagingPrice }} грн
                </p>
                <p class="font-bold text-xl">
                    Загальна вартість:
                    {{
                        (
                            parseFloat(discountedTotalPrice.toFixed(2)) +
                            totalPackagingPrice
                        ).toFixed(2)
                    }}
                    грн
                </p>
                <button
                    v-if="sidebarWidth !== '120px'"
                    @click="goToCheckout"
                    class="w-full mt-4 bg-green-500 text-white px-4 py-2 rounded font-bold text-xl"
                >
                    Замовити
                </button>
            </div>
            <div v-else class="bg-submain_color p-4 text-white">
                <p class="font-bold text-md text-center">
                    {{
                        (
                            parseFloat(finalTotalCost)
                        ).toFixed(2)
                    }}
                    грн
                </p>
            </div>
        </div>
        <div
            v-if="isMobile"
            @click="toggleSidebar"
            class="flex flex-row items-center fixed top-5 right-4 bg-submain_color rounded-full p-2 shadow-lg z-50 cursor-pointer transition-opacity duration-500"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-10 h-10 mx-3 text-white"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
            </svg>
            <span class="text-2xl text-white mr-3 font-bold">{{
                totalItems
            }}</span>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from "vue";
import { useCartStore } from "../Stores/cartStore.js";
import { useOrderStore } from "../Stores/orderStore.js";
import { Inertia } from "@inertiajs/inertia";
import emitter from "../eventBus";

const cartStore = useCartStore();
const orderStore = useOrderStore();
const storedDeliveryMethod = ref("");
const discountAmount = ref(0);

// Получаем значения из cartStore
const cartItems = ref([]);
const totalItems = computed(() => cartStore.totalItems || 0);
const totalPrice = computed(() => cartStore.totalPrice || 0);
const totalPackagingPrice = computed(() => cartStore.totalPackagingPrice || 0);

// Вычисляем стоимость с учетом скидки. скидка 15%
const discountedTotalPrice = computed(() => {
    if (storedDeliveryMethod.value === "pickup") {
        discountAmount.value = parseFloat((totalPrice.value * 0.15).toFixed(2));
        return totalPrice.value - discountAmount.value;
    }
    discountAmount.value = 0;
    return totalPrice.value;
});

// Итоговая стоимость с учетом упаковки
const finalTotalCost = computed(() => {
    return discountedTotalPrice.value + totalPackagingPrice.value;
});

// Сохраняем итоговую стоимость в localStorage при её изменении
watch(finalTotalCost, (newTotalCost) => {
    localStorage.setItem("finalTotalCost", newTotalCost.toFixed(2));
    orderStore.setTotalCost(newTotalCost); // Сохраняем также в orderStore для отправки заказа
});

const goToCheckout = () => {
    Inertia.visit("/checkout");
};

// Обработчик события изменения метода доставки
emitter.on("deliveryMethodChanged", (newMethod) => {
    storedDeliveryMethod.value = newMethod;
});

// Слежение за изменениями в корзине
watch(
    () => cartStore.items,
    (newItems) => {
        cartItems.value = newItems;
    },
    { deep: true }
);

onMounted(() => {
    cartStore.loadCartFromLocalStorage();
    cartItems.value = cartStore.items;
    orderStore.setTotalCost(finalTotalCost.value); // Устанавливаем значение при загрузке
});

// Управление количеством товаров в корзине
const increaseQuantity = (productId) => {
    const product = cartItems.value.find((item) => item.id === productId);
    if (product) {
        updateItemQuantity(productId, product.quantity + 1);
    }
};

const decreaseQuantity = (productId) => {
    const product = cartItems.value.find((item) => item.id === productId);
    if (product && product.quantity > 1) {
        updateItemQuantity(productId, product.quantity - 1);
    }
};

const updateItemQuantity = (productId, quantity) => {
    cartStore.updateQuantity(productId, quantity);
};

const removeFromCart = (productId) => {
    cartStore.removeFromCart(productId);
};

// Управление боковой панелью
const isMobile = ref(window.innerWidth <= 768);
const showSidebar = ref(!isMobile.value);
const sidebarWidth = ref("120px");

const expandSidebar = () => {
    if (!isMobile.value) {
        sidebarWidth.value = "360px";
    }
};

const collapseSidebar = () => {
    if (!isMobile.value) {
        sidebarWidth.value = "120px";
    }
};

const toggleSidebar = () => {
    showSidebar.value = !showSidebar.value;
    sidebarWidth.value = showSidebar.value ? "360px" : "120px";
};

window.addEventListener("resize", () => {
    isMobile.value = window.innerWidth <= 768;
    showSidebar.value = !isMobile.value;
});

// Вспомогательная функция для получения пути изображения
const imagePath = (path) => {
    return `/storage/${path}`;
};
</script>

<style scoped>
.sidebar {
    transition: width 0.3s;
}
</style>
